import React from 'react';
import { BsLinkedin, BsInstagram} from 'react-icons/bs';
import { FaFacebook, FaGithub} from 'react-icons/fa';

const SocialMedia = () => {
  return (
    <div className="app__social">
        <a href="https://www.linkedin.com/in/nilson-gon%C3%A7alves-ab85a9213/" target="_blank">
          <div>
            <BsLinkedin/>
          </div>
        </a>
        <a href="https://www.facebook.com/nilsonlaercio.botelho/" target="_blank">
          <div>
            <FaFacebook/>
          </div>
        </a>
        <a href="https://www.instagram.com/nilsongoncalves__/" target="_blank">
          <div>
            <BsInstagram/>
          </div>
        </a>
        <a href="https://github.com/NLBGoncalves" target="_blank">
          <div>
            <FaGithub/>
          </div>
        </a>
    </div>
  )
}

export default SocialMedia;